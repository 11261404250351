import React, {useEffect, useState} from "react";
import "./Soap.css"
import { useContext } from "react";
import GPTContext from "./context/GPTContext";
import PatientContext from "./context/PatientContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import s1 from "../assets/s1.png";
import o1 from "../assets/o1.png";
import o2 from "../assets/o2.png";
function Soap()
{
    const patient_data = useContext(PatientContext);
    const gpt_context = useContext(GPTContext);
    const [feedback, setFeedback] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigate();

    const regen = async ()=>{
        setLoading(true);
        const data = patient_data.state_;
        const prev_res = gpt_context.state_["subjective"] + "\n" + gpt_context.state_["objective"] + "\n" + gpt_context.state_["assessment"]
        const regen_data = {
            "data" : data,
            "prev_res" : prev_res,
            "feedback" : feedback
        }
        await axios.post("https://flask-production-5f72.up.railway.app/regen", regen_data).then((response)=>{
            const soapNote = response.data;

            const subjectiveIndex = soapNote.indexOf("Subjective:");
            const objectiveIndex = soapNote.indexOf("Objective:");
            const assessmentIndex = soapNote.indexOf("Assessment:");
            const planIndex = soapNote.indexOf("Plan:");

            const subjectivePart = soapNote.substring(subjectiveIndex, objectiveIndex).replace("Subjective:", "").trim();
            const objectivePart = soapNote.substring(objectiveIndex, assessmentIndex).replace("Objective:", "").trim();
            const assessmentPart = soapNote.substring(assessmentIndex, planIndex).replace("Assessment:", "").trim();
            const planPart = soapNote.substring(planIndex).replace("Plan:", "").trim();

            console.log("Subjective:", subjectivePart);
            console.log("Objective:", objectivePart);
            console.log("Assessment:", assessmentPart);
            console.log("Plan:", planPart);

            const local_data = {
                "subjective" : subjectivePart,
                "objective" : objectivePart,
                "assessment" : assessmentPart,
                "plan" : planPart,
            }

            gpt_context.update(local_data);
            setLoading(false);


        }).catch((error)=>{
            console.log(error);
        })
    }



    return(
        <>
        <div id="patient_info">
            <div id="patient_image">

            </div>

            <div id="details">
                <p>{patient_data.state_["name"]}</p>
                <p>Gender: {patient_data.state_["gender"]} | Age: {patient_data.state_["age"]} | DOB: {patient_data.state_["dob"]} | MRN: {patient_data.state_["MRN"]} | Language: {patient_data.state_["language"]} </p>
                <p>Family Contact: {patient_data.state_["contact"]}</p>
                <p>Physician: {patient_data.state_["physician"]}</p>
            </div>
            <div id="contact">
                <button>Chat</button>
                <button>Video Visit</button>
                <button>Phone Call</button>
            </div>
        </div>

        <div style = {{marginTop:"2%", marginLeft:"3%", width:"80vw", display:"flex", flexDirection:"column", alignItems:"flex-start"}} id="panel">
            <input onChange={(e)=>{setFeedback(e.target.value);}} placeholder="Regenerate with Feedback" style = {{padding:"1%", width:"30%"}} type = "text"/>
            <div style = {{marginTop:"1%", display:"flex", flexDirection:"row", width:"20%", alignItems:"center", justifyContent:"space-between"}} id="panel_btn">
                <button onClick={()=>{regen();}} style = {{marginTop:"2%", backgroundColor : "green", padding:"4%", color:"white", borderRadius:"25px", width:"7vw"}}>Regenerate</button>
                <button onClick={()=>{navigation("/doctor")}} style = {{marginLeft:"3%", backgroundColor : "red", padding:"4%", color:"white", borderRadius:"25px", width:"7vw"}}>Return</button>
            </div>
        </div>


        <div id="results">
            <h2>Subjective</h2>
            <p>{gpt_context.state_["subjective"]}</p>
            <img src = {s1} alt = "subjective rating"/>

        </div>

        <div id="results">
            <h2>Objective</h2>
            <p>{gpt_context.state_["objective"]}</p>
            <div style = {{display : "flex", flexDirection:"row"}} id = "obj_imgs">
                <img src = {o1} alt = "graph"/>
                <img style = {{marginLeft:"5%"}} src = {o2} alt = "factors"/>
            </div>
        </div>

        <div style = {{marginBottom:"0%"}} id="results">
            <h2>Assessment</h2>
            <p>{gpt_context.state_["assessment"]}</p>
        </div>

        <div style = {{marginBottom:"3%"}} id="results">
            <h2>Plan</h2>
            <p>{gpt_context.state_["plan"]}</p>
        </div>
        {isLoading ? <Loader/>: ""}
        </>
    );
}

export default Soap;