import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import "./HeroRefUser.css";
import cardImage from "../assets/football.png";
import stars from "../assets/stars.png";
import { CircleLoader } from "react-spinners";

function HeroRefUser()
{
    const location = useLocation();
    const recommendations = location.state;

    const [email, setEmail] = useState("");
    const [cell, setCell] = useState("");
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const send_email = ()=>{


      const emailBody_ = `
      <h2>Recommendation Name</h2>
      <p>${recommendations.name}</p>
      <h2>Recommendation Link</h2>
      <p>${recommendations.url}</p>
      <h2>Query</h2>
      <p>${query}</p>
      `
        setIsLoading(true);
        console.log(email)
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer sk_e82dc80705a378f18d1cd7ed3d1e82a01a5131571c71cf51`, // Replace 'token' with your actual authorization token
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            to: email,
            subject: recommendations.name,
            body: emailBody_,
            subscribed: true,
            name: "Hero"
          })
        };
        
        fetch('https://api.useplunk.com/v1/send', options)
            .then(response => response.json())
          .then(response => console.log(response))
          .catch(err => console.error(err));
          setIsLoading(false);
    }

    return(

        <>
        {isLoading ? 
            <>
                <div style = {{width:"100vw", height:"100vh", display:"flex", flexDirection:"column", justifyContent : "center", alignItems:"center", backgroundColor:"rgba(0, 0, 0, 0.67)", position:"fixed"}}>
                    <CircleLoader color = "#36d7b7"/>
                    <p style = {{textAlign:"center", color:"white"}}>Loading ...</p>
                </div>
            </>
             : <></>}

        <div id="hero-ref-user-container">
            <div id="hero-rec-banner">
                <p>STEM Elementary</p>
            </div>

            <div id="refferal-form-user">
                <input onChange={(e)=>{setEmail(e.target.value)}} type = "email" placeholder="Enter Email"/>
                <textarea onChange={(e)=>{setQuery(e.target.value)}}  placeholder="Enter Text"/>
                <button onClick={()=>{send_email();}}>Send</button>
            </div>

            <div id="hero-recommendations-ref">
                <p>Recommendations</p>

                {
                            <>
                            <div id="hero-rec-card">
                                <img src = {cardImage} alt = "card"/>
                                <div id="hero-rec-card-subsection">
                                    <div className="subsection-rows">
                                        <h1 onClick={()=>{
                                            window.location.href = recommendations.url;
                                        }} >{recommendations.name}</h1>
                                    </div>
                                    <div className="subsection-rows">
                                        <p>{recommendations.name} is the best place for your improvement, based on the stats provided, our system came up with this recommendation.</p>
                                    </div>
                                </div>
                            </div>
                            </>
                }

            </div>
        </div>
        </>
    );
};

export default HeroRefUser;