import React from "react";
import "./Home.css"; 
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import home_art from "./Assets/home_art.png";

function Home()
{
    return(
        <>
        <Navbar/>
        <div id="homeContainer">
            <div id="homeContent">
                <h1>Chat with your own data</h1>
                <p>Empowering Your Wellness Journey</p>
                <p>We can provide a way to present the data using AI in easy-to-understand language by including interactive steps, questions and answers. It can also contain links to provide further information and explanations on various conditions.</p>  
                <div id="homeButtons">
                    <Link style = {{textDecoration:"none"}} id="homebtn" to = "/login"><button>Login</button></Link>
                </div>
            </div>    

            <img src = {home_art} alt="vector art"/>


        </div>
        </>
    );
}

export default Home;