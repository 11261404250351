import React from "react";
import "./ChatBubble.css";
function ChatBubble(props)
{
    return(
        <>

        {
            props.type === "bot" ? <div id="bubble"><p>{props.message}</p></div>  : 
            <div id="bubbleUser"><p>{props.message}</p></div>
        }



        </>
    );
}

export default ChatBubble;