import React, {useState} from "react";
import AuthContext from "./AuthContext";

const AuthState = (props)=>{
    const state = {
        "login_user": false,
        "login_admin":false
    }

    const [state_, setState] = useState(state);

    const update = (type, status)=>{
        var temp_state = state;
        if(type === "user")
        {
            temp_state['login_user'] = status;
        }
        else if(type === "admin")
        {
            temp_state['login_admin'] = status;
        }
        
    }
    return (
        <AuthContext.Provider value = {{state, update}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;