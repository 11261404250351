import React, {useState} from "react";
import "./HeroUserSelect.css";
import patientImage from "../assets/item-photo.png";
import c1 from "../assets/c2.jpeg";
import c2 from "../assets/c3.jpeg";
import { useNavigate } from "react-router-dom";
function HeroUserSelect()
{
    const navigation = useNavigate();
    const [patientName, setPatientName] = useState("");
    const [patientNumber, setPatientNumber] = useState("");
    const [patientImages, setPatientImages] = useState([patientImage, c1, c2]);

    const [headingName, setHeadingName] = useState("");
    const [heading, setHeading] = useState("");

    const patients = [
        {
            "name": "John Smith",
            "gender" : "Male",
            "MRN" : "12345678",
            "language" : "English",
            "contact" : "Larry Smith (Father)",
            "physician": "Surbhi Bhargava, M.D",
            "mental" : "8",
            "sleep" : "8",
            "mood" : "8",
            "food" : "8",
            "stress" : "8",
            "anxiety" : "3",
            "dob": "14/2009",
            "age" : "13"
        },
        {
            "name": "Lucia Garcia",
            "gender" : "Female",
            "MRN" : "345671234",
            "language" : "Spanish",
            "contact" : "Martin Garcia(Father)",
            "physician": "Mathew R. Hernandez, M.D.",
            "mental" : "4",
            "sleep" : "6",
            "mood" : "5",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "15/2008",
            "age" : "14"
        },
        {
            "name": "Stephanie Kim",
            "gender" : "Female",
            "MRN" : "67890123",
            "language" : "English",
            "contact" : "Mady Kim (Mother)",
            "physician": "Luis A. Folan, M.D.",
            "mental" : "6",
            "sleep" : "5",
            "mood" : "4",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "11/2013",
            "age" : "11"
        },
    ]


    return(
        <>
        <div id="hero-user-container">
            <div id="hero-user-top-bar">
                <p>STEM Elementary</p>
            </div>

            <div id="hero-user-input">
                <p>Hello John</p>
                <p>“Be the change you want to see in the world”</p>
                <div id = "hero-user-input-row">
                    <div className="hero-user-input-row-section">
                        <p>Patient Number</p>
                        <input onChange={(e)=>{setPatientNumber(e.target.value);}} type = "text" placeholder="1234567"/>
                    </div>

                    <div className="hero-user-input-row-section">
                        <p>Name</p>
                        <input onChange={(e)=>{setPatientName(e.target.value);}} type = "text" placeholder="Stephanie"/>
                    </div>

                </div>

            </div>

            {
                patients.map((n,i)=>{
                    if(patients[i].name === patientName || patients[i].MRN === patientNumber)
                    {
                        return(
                            <div id = "hero-search-card">
                                <div id="search-card-row1">
                                    <img src = {patientImages[i]} alt = "patient"/>
                                    <div id="r1-subsection">
                                        <p>{patients[i]["name"]}</p>
                                        <p>Gender: {patients[i]["gender"]} | Age: {patients[i]["age"]} | DOB: {patients[i]["dob"]} | MRN: {patients[i]["MRN"]} | Language: {patients[i]["language"]} </p>
                                        <p>Family Contact: {patients[i]["contact"]}</p>
                                        <p>Physician: {patients[i]["physician"]}</p>
                                        <div id="subsection-buttons">
                                            <button onClick={()=>{navigation("/herorec", {state : patients[i].name})}}>Yes, Open Charts</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                })
            }

        </div>
        </>
    );
};

export default HeroUserSelect;