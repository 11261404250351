import React, {useState} from "react";
import GPTContext from "./GPTContext";

const GPTState = (props)=>{
    const state = {
        "result" : "No Result"
    }

    const [state_, setState] = useState(state);

    const update = (data)=>{
        setState(data);
    }

    return(
        <GPTContext.Provider value = {{state_, update}}>
            {props.children}
        </GPTContext.Provider>
    )
}

export default GPTState;