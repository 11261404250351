// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3r-WA2i1ySigYzDr2i829k6vtxI9DV4o",
  authDomain: "new-ai-project-382400.firebaseapp.com",
  projectId: "new-ai-project-382400",
  storageBucket: "new-ai-project-382400.appspot.com",
  messagingSenderId: "960539138",
  appId: "1:960539138:web:239d50e79a749d4914951b",
  measurementId: "G-ZEKDM86F5Q"
};

const firebaseConfigAdmin = {

  apiKey: "AIzaSyDOTB8wbUnT9MEPYcSGv0y7WV133p6Q7BU",
  authDomain: "bot-admin-9c1ce.firebaseapp.com",
  projectId: "bot-admin-9c1ce",
  storageBucket: "bot-admin-9c1ce.appspot.com",
  messagingSenderId: "556887885252",
  appId: "1:556887885252:web:a3fe8d7daa485a30e91091",
  measurementId: "G-SSR0JKLY8Y"
};

const firebaseConfig_phyc = {
  apiKey: "AIzaSyC_cgn4W1uJM3EprSdueARk3xmdQTuJCVQ",
  authDomain: "welfie-61e97.firebaseapp.com",
  projectId: "welfie-61e97",
  storageBucket: "welfie-61e97.appspot.com",
  messagingSenderId: "1034497484621",
  appId: "1:1034497484621:web:4435f90e5aaf1437c8f904"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appAdmin = initializeApp(firebaseConfigAdmin, "bot-admin");

export const appPhyc = initializeApp(firebaseConfig_phyc, "Welfie");
export const auth = getAuth(app);
export const authAdmin = getAuth(appAdmin);

export const db = getFirestore(app);
export const storage = getStorage(app);