import React, {useState} from "react";
import "./ChatArea.css";
import ChatBubble from "./ChatBubble";
import axios from "axios";
import Loader from "./Loader";


var chat = [];
function ChatArea(props)
{
    const [query, setQuery] = useState("");
    const [isLoading, setLoading] = useState(false);

    const ask_gpt = async ()=>{
        setLoading(true);
        chat.push({type : "user" , message : query});
        const data = {question : query};
        try {
            const response = await axios.post('https://flask-production-5903.up.railway.app/results', data);
            const result = response.data;
            console.log("Result" , result);
            chat.push({type : "bot" , message : result});

          } catch (error) {
            alert(error);
          }

        setLoading(false);
    }
    return(
        <>
        <div id="chatContainer">
            {
                isLoading ? <Loader/>:
                
                    chat.map((item)=>{
                        console.log("Chats : ",item);
                        return (<ChatBubble type = {item["type"]} message = {item["message"]}/>)
                    })    
            }

        </div>
        <div  id="chatInput">
            <input disabled = {props.disable} type = "text" placeholder="Enter your queries here ..." onChange={(e)=>{setQuery(e.target.value)}}/>
            <button onClick={()=>{ask_gpt()}}>Ask</button>
        </div>
        {isLoading ? <Loader/>:""}
        </>
    );
}


export default ChatArea;