import React , {useState} from "react";
import "./Signup.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import {auth} from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Audio } from 'react-loader-spinner';
import Loader from "./Loader";
import {db} from "../firebase";
import { collection, addDoc } from "firebase/firestore";



function Signup()
{
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigate();



    const sign_up = async ()=>{
        setLoading(true);
        const doc = document.getElementById("signupContainer");
        doc.style.filter = "blur(50px)";
        const userDetails = collection(db, "userDetails");
        const data = {
            email : email,
            password: password, 
            time : Date.now(),
            username : username
        }

        var error_ = null;
        createUserWithEmailAndPassword(auth, email, password).then((userCredentials)=>{addDoc(userDetails, data);setLoading(false);doc.style.filter = "blur(0px)";console.log(userCredentials);navigation("/login");}).catch((error)=>{error_ = error ;setLoading(false);doc.style.filter = "blur(0px)"; alert("Account already exists")});

    }

    isLoading? <div>Loading</div>:
    <></>

        return(
        <div id = "signupPage">
            <Navbar/>
            <div id="signupContainer">
                <div id="signupForm">
                    <h1>Sign Up</h1>
                    <input type = "text" placeholder="Username" onChange={(e)=>{setUsername(e.target.value)}}/>
                    <input type = "text" placeholder="Email Address" onChange={(e)=>{setEmail(e.target.value)}}/>
                    <input type = "password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                    <p>Already have an account? <Link style = {{textDecoration:"none"}} to ="/login"><p>Login</p></Link></p>
                    <button onClick={()=>{sign_up()}}>Sign Up</button>
                </div>
            </div>
            {isLoading ? <Loader/>: ""}
        </div>
    );
}

export default Signup;