import React, {useState} from "react";
import PatientContext from "./PatientContext";

const PatientState = (props)=>{

    const state = {
        "name": "Derek vo",
        "gender" : "Male",
        "MRN" : "87654321",
        "language" : "Vietnamese ",
        "contact" : "Valeria Vo (Mother)",
        "physician": "Christina T. Devine, M.D.",
        "mental" : "6",
        "sleep" : "4",
        "mood" : "5",
        "food" : "5",
        "stress" : "4",
        "anxiety" : "4",
        "dob" : "12/2014",
        "age" : "10"
    };

    const [state_, setState] = useState(state);

    const update_patients = (new_data)=>{
        setState(new_data);
    }

    return (
        <PatientContext.Provider value = {{state_, update_patients}}>
            {props.children}
        </PatientContext.Provider>
    )
}

export default PatientState;