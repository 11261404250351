import React from "react";
import "./NavbarPhyc.css";
import p1 from "../assets/p1.png";
import cross from "../assets/cross.png";
import heart from "../assets/heart.png";
import dp from "../assets/dp.png";

function NavbarPhyc()
{
    return(
        <>
        <div id="navbar_phyc">
            <div id="nav_left_phyc">
                <div id="menu_phyc">
                    <div id="l1"></div>
                    <div id="l2"></div>
                    <div id="l3"></div>
                </div>

                <div id="patient_bar_phyc">
                    <img src = {p1} alt = "patient_image"/>
                    <p>Stephenie Kauras</p>
                    <img src = {cross} alt = "cross"/>
                </div>

            </div>

            <div id="nav_right_phyc">
                <img src = {heart} alt = "heart"/>
                <img src = {dp} alt = "dp"/>
            </div>
        </div>
        </>
    );
};

export default NavbarPhyc;
