import React from 'react';
import "./AdminNav.css";
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from './context/AuthContext';
function AdminNav()
{
    const auth_verif = useContext(AuthContext);

    const getMenu = ()=>{
        const menu = document.getElementById("adminNavMobile");
        menu.style.transform = "translateX(0px)";
    }

    const closeMenu=  ()=>{
        const menu = document.getElementById("adminNavMobile");
        menu.style.transform = "translateX(1000px)";
    }
    return(
        <>

        <div id="adminNavMobile">"
            <Link to = "/createadmin" style = {{textDecoration:"none"}}><p>Create Admin + </p></Link>
            <Link to = "/viewgtc" style = {{textDecoration:"none"}}><p>View GTC's</p></Link>
            <Link to = "/viewusers" style = {{textDecoration:"none"}}><p>User Details</p></Link>
            <Link to = "/addgtc" style = {{textDecoration:"none"}}><p>Create GTC +</p></Link>
            <Link onClick={()=>{auth_verif.update("admin", false);}} to = "/admin" style = {{textDecoration:"none"}}><p>Log Out</p></Link>
            <Link to = "/" style = {{textDecoration:"none"}}><p>User</p></Link>
            <p onClick={()=>{closeMenu()}}>Close</p>
        </div>
        <div onClick={()=>{getMenu()}} id="menu">
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div id="adminNav">
            <div id="adminNavLeft">
                <Link to = "/createadmin" style = {{textDecoration:"none"}}><p>Create Admin + </p></Link>
                <Link to = "/viewgtc" style = {{textDecoration:"none"}}><p>View Records</p></Link>
                <Link to = "/addgtc" style = {{textDecoration:"none"}}><p>Create Records</p></Link>
                <Link to = "/viewusers" style = {{textDecoration:"none"}}><p>User Details</p></Link>
                <Link to = "/" style = {{textDecoration:"none"}}><p>User</p></Link>


            </div>

            <div id="adminNavRight">
            <Link onClick={()=>{auth_verif.update("admin", false);}} to = "/admin" style = {{textDecoration:"none"}}><p>Log Out</p></Link>
            </div>
        </div>
        </>
    );
}

export default AdminNav;