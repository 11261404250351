import React, {useState, useEffect} from "react";
import AdminNav from "./AdminNav";
import "./ViewGTC.css";
import list from "./Assets/list.png"
import close from "./Assets/close.png";
import { collection, deleteDoc, getDocs, doc } from "firebase/firestore";
import { db } from "../firebase";
import Loader from "./Loader";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
import { useNavigate } from "react-router-dom";
function ViewGTC()
{
    const [docNames, setDocNames] = useState([]);
    const [companyNames, setCompanyNames] = useState([]);
    const [productNames, setProductNames] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [c_doc, set_c_doc] = useState("");
    const [c_product, set_c_product] = useState("");
    const [c_company, set_c_company] = useState("");

    const navigation = useNavigate();
    const auth_verif = useContext(AuthContext);


    const seeInfo = (doc, product, company)=>{
        const gtc = document.getElementById("gtcDetail");
        gtc.style.display = "flex";
        set_c_doc(doc);
        set_c_company(company);
        set_c_product(product);
    }

    const remove_gtc = async (document)=>{
        const gtcCollection = collection(db, "gtcDetails");
        const gtcDocs = await getDocs(gtcCollection);
        var docId;

        gtcDocs.forEach((doc)=>{
            if(doc.data()["document_name"] === document)
            {
                docId = doc.id;
                console.log(docId);
            }
        })

        const docRef = doc(db, "gtcDetails", docId);

        deleteDoc(docRef)
        .then(() => {
            alert("GTC Deleted");
        })
        .catch(error => {
            alert(error);
        })

        get_gtc();

    }

    const confirmation = (doc, company, product)=>{
        const confirm = document.getElementById("gtcConfirmation");
        confirm.style.display = "flex";
        set_c_doc(doc);
        set_c_company(company);
        set_c_product(product);
    }

    const closeInfo = ()=>{
        const gtc = document.getElementById("gtcDetail");
        gtc.style.display = "none";
    }
    
    
    const closeConfirmation = (response)=>{

        if(response === "yes")
        {
            remove_gtc(c_doc);
        }

        const confirm = document.getElementById("gtcConfirmation");
        confirm.style.display = "none";

    }

    const get_gtc = async ()=>{
        setLoading(true);
        const doc = document.getElementById("gtcDetail");
        doc.style.filter = "blur(50px)";


        const gtcCollection = collection(db, "gtcDetails");
        var doc_names = [];
        var company_names = [];
        var product_names = [];

        const gtcDocs = await getDocs(gtcCollection);

        gtcDocs.forEach((doc)=>{
            doc_names.push(doc.data()["document_name"]);
            company_names.push(doc.data()["company_name"]);
            product_names.push(doc.data()["product_name"]);
        })

        setDocNames(doc_names);
        setCompanyNames(company_names);
        setProductNames(product_names);

        setLoading(false);
        doc.style.filter = "blur(0px)";
        console.log(company_names);



    }

    useEffect(()=>{
        get_gtc();
    } , [])

    if(auth_verif.state["login_admin"] === false)
    {
        navigation("/admin");
    }



    return(
        <>
            <AdminNav/>

            <div id="gtcDetail">
                <p>Document Name: {c_doc}</p>
                <p>Record Company: {c_company}</p>
                <p>Record Product: {c_product}</p>
                <button onClick={()=>closeInfo()}>Close</button>
            </div>

            <div id="gtcConfirmation">
                <p>Are you sure you want to delete the Record ?</p>
                <div id="choice">
                    <button onClick={()=>{closeConfirmation("no")}}>No</button>
                    <button onClick={()=>{closeConfirmation("yes");}}>Yes</button>
                </div>
            </div>

            <div id="viewgtcContainer">
                <div id="gtcList">
                    <h1>Admin Panel</h1>
                    <p>Current Records</p>
                    <div id="gtcNames">

                        {companyNames.map((el,i)=>{
                            return(
                                <div>
                                    <img onClick={()=>{seeInfo(docNames[i], productNames[i], el)}} src = {list} alt = "info"/>
                                    <p>{el}</p>
                                    <img onClick={()=>{confirmation(docNames[i], el, productNames[i])}} src = {close} alt = "delete"/>
                                </div>
                            )
                        })}
        
                    </div>
                </div>
            </div>

            {isLoading ? <Loader/> : ""}
        </>
    );
}

export default ViewGTC;