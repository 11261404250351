import React, {useState} from "react";
import './Admin.css';
import { Link } from "react-router-dom";
import {authAdmin} from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";

function Admin()
{

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigate();
    const auth_verif = useContext(AuthContext);


    const sign_in = ()=>{
        setLoading(true);
        const doc = document.getElementById("adminContainer");
        doc.style.filter = "blur(50px)";
        signInWithEmailAndPassword(authAdmin , email, password).then((user)=>{auth_verif.update("admin", true);setLoading(false);doc.style.filter = "blur(0px)"; navigation("/createadmin")}).catch((error)=>{alert(error); setLoading(false);doc.style.filter = "blur(0px)";});
    }

    return (
        <>
        <div id="adminContainer">
            <div id="adminLoginForm">
                <h1>Admin Panel</h1>
                <input type = "text" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
                <input type = "password" placeholder = "Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                <div onClick={()=>{sign_in()}} style = {{textDecoration:"none"}} id="adminButton" to ="#"><button>Login</button></div>
            </div>
        </div>
        {isLoading ? <Loader/> : ""}
        </>
    );
}

export default Admin;