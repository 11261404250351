import React, { useState, useEffect } from "react";
import AdminNav from "./AdminNav";
import "./ViewUsers.css";
import close from "./Assets/close.png";
import {db } from "../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import Loader from "./Loader";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";

function ViewUsers() {
  const auth_verif = useContext(AuthContext);
  const [email, setEmail] = useState([]);
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState([]);
  const [time, setTime] = useState([]);
  const [date, setDate] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [del, setDel] = useState("");
  const navigation = useNavigate();

  useEffect(()=>{
    if(auth_verif.state["login_admin"] === false)
    {
        navigation("/admin");
    }
  },[auth_verif, navigation])



  const confirmation = (email_) => {
    const confirm = document.getElementById("userConfirmation");
    confirm.style.display = "flex";
    setDel(email_);
  };

  const closeConfirmation = () => {
    const confirm = document.getElementById("userConfirmation");
    confirm.style.display = "none";
  };

  const delete_user = async (email_) => {
    const userRef = collection(db, "userDetails");
    const docRefs = await getDocs(userRef);
    let docId;

    docRefs.forEach((doc) => {
      if (doc.data()["email"] === email_) {
        console.log(email_);
        docId = doc.id;
        console.log(docId);
      }
    });

    const docRef = doc(db, "userDetails", docId);

    deleteDoc(docRef)
      .then(() => {
        alert("User Deleted");
      })
      .catch((error) => {
        alert(error);
      });

    get_users();
  };

  const get_users = async () => {
    setLoading(true);
    const userRef = collection(db, "userDetails");
    const docRefs = await getDocs(userRef);
    const emails = [];
    const usernames = [];
    const passwords = [];
    const dates = [];
    const times = [];

    docRefs.forEach((doc) => {
      emails.push(doc.data()["email"]);
      usernames.push(doc.data()["username"]);
      passwords.push(doc.data()["password"]);
      const dateFormat = new Date(doc.data()["time"]);
      dates.push(dateFormat.toDateString());
      times.push(dateFormat.toTimeString());
    });

    if (docRefs != null) {
      setEmail(emails);
      setUsername(usernames);
      setPassword(passwords);
      setTime(times);
      setDate(dates);
      setLoading(false);
      console.log(email);
      console.log(username);
      console.log(password);
      console.log(time);
      console.log(date);
    }
  };

  useEffect(() => {

    get_users();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <AdminNav />

      <div id="userConfirmation">
        <p>Are you sure you want to delete the user?</p>
        <div id="userChoice">
          <button onClick={() => closeConfirmation()}>No</button>
          <button
            onClick={() => {
              closeConfirmation();
              delete_user(del);
            }}
          >
            Yes
          </button>
        </div>
      </div>

      <div id="userDetails">
        <div id="userHeading">
          <h1>Admin Panel</h1>
          <p>User Details</p>
        </div>

        <div id="userList">
          {isLoading ? (
            <Loader />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {email.map((el, i) => (
                  <tr key={el}>
                    <td>{username[i]}</td>
                    <td>{el}</td>
                    <td>{date[i]}</td>
                    <td>{time[i]}</td>
                    <td>
                      <img
                        onClick={() => confirmation(el)}
                        src={close}
                        alt="remove user"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewUsers;
