import React, { useState, useEffect, useRef } from "react";
import NavbarPhyc from "./NavbarPhyc";
import "./Phyc.css";
import doctor from "../assets/doctor.png";
import p1 from "../assets/p1.png";
import report from "../assets/report.png";
import regen_ from "../assets/regen.png";
import edit from "../assets/edit.png";
import add from "../assets/add.png";
import axios from "axios";
import { CircleLoader } from "react-spinners";
import jsPDF from 'jspdf';
import uploadPDF from '../firebase/uploadFile';

function Phyc() {
  const [results, setResults] = useState([]);
  const [prompt, setPrompt] = useState("provide reason for referral");
  const [isLoading, setLoading] = useState(false);

  const [containerHeight, setContainerHeight] = useState("");

  const [regen_prompt, setRegenPrompt] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [updatedEditedContent, setUpdatedEditedContent] = useState("");
  const [editing, setEditing] = useState(false);

  const contanerRef = useRef(null);
  const url = "https://34.28.224.150";
  const userId = '51';

  const get_patient_data = async ()=>{
    await axios.get(`${url}/patient`).then((response)=>{
      console.log(response.data);
    }).catch((error)=>{
      alert(error);
    })
  }




    const download_pdf = ()=>{
        setLoading(true);

        if(results.length < 1)
        {
          setLoading(false);
          return 0;
        }
      const doc = new jsPDF({
        format: 'a0',
        unit: 'px',
      });
    
        const controls = document.getElementsByClassName("ctrl");
        const template = document.getElementById("template");
        const pdf = document.getElementById("generate_report");

        for (let i = 0; i < controls.length; i++) {
          // You can set the display property to "none", "block", "inline", etc.
          controls[i].style.display = "none"; // Change "block" to your desired display value
        }
        template.style.display = "none";
        pdf.style.display = "none";
        doc.setFont('Inter-Regular', 'normal');


        doc.html(contanerRef.current, {
            async callback(doc) {
                const pdfBlob = await doc.output('blob');
                doc.save("phycic_report.pdf");
                
                const downloadURL = await uploadPDF(pdfBlob, userId);
                console.log('Download URL:', downloadURL);
                const response = await axios.get(`${url}/addUserResult`, {
                  params: {
                    userId,
                    resultUrl: downloadURL,
                  },
                });
                if (response.data) {
                  console.log('User result added:', response.data);
                } else {
                  console.error('Failed to add user result:', response.status, response.statusText);
                }
                setLoading(false);
                template.style.display = "flex";
                pdf.style.display = "flex";
                for (let i = 0; i < controls.length; i++) {
                  // You can set the display property to "none", "block", "inline", etc.
                  controls[i].style.display = "flex"; // Change "block" to your desired display value
                }
            },
        });

    
    }

  const prompts = {
    "Reason for Referral": "provide reason for referral",
    "Types Of Evaluation": "provide general behavioral observations",
    "Background Information": "provide background information",
    "Behavioral Observations":
      "provide Types of evaluation and information gathered",
    "Test Findings": "provide testing findings",
    Conclusion: "provide general Conclusion",
    Diagnosis: "provide general Diagnosis",
    Recommendations: "provide general Recommendations",
    "Welfie C.A.R.E. Plan": "provide general C.A.R,E plan",
  };

  var data = {
    name: "Derek vo",
    gender: "Male",
    MRN: "87654321",
    language: "Vietnamese ",
    contact: "Valeria Vo (Mother)",
    physician: "Christina T. Devine, M.D.",
    mental: "6",
    sleep: "4",
    mood: "5",
    food: "5",
    stress: "4",
    anxiety: "4",
    dob: "12/2014",
    age: "10",
    type: prompt,
  };

  const get_results = async () => {
    setLoading(true);
    await axios
      .post("https://34.28.224.150/results", JSON.stringify(data),  {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        var heading;
        console.log(prompt);
        for (let key in prompts) {
          if (prompts[key] === prompt) {
            heading = key;
            console.log(heading);
          }
        }

        var match = false;
        var match_index;

        if (results.length >= 1) {
          results.map((n, i) => {
            if (results[i]["heading"] === heading) {
              match = true;
              match_index = i;
              return true;
            }
          });

          if (match === true) {
            setResults((prevResults) => {
              const updatedResults = [...prevResults];
              updatedResults[match_index] = {
                ...updatedResults[match_index],
                answer: response.data,
              };
              setLoading(false);
              return updatedResults;
            });

            const doc = document.getElementById(heading);
            doc.style.backgroundColor = "#38B2AC";
            doc.style.color = "white";
          } else if (match === false) {
            const result = {
              heading: heading,
              answer: response.data,
            };

            setResults((prevResults) => [...prevResults, result]);
            const doc = document.getElementById(heading);
            doc.style.backgroundColor = "#38B2AC";
            doc.style.color = "white";
            setLoading(false);
          }
        } else {
          const result = {
            heading: heading,
            answer: response.data,
          };

          setResults((prevResults) => [...prevResults, result]);
          const doc = document.getElementById(heading);
          doc.style.backgroundColor = "#38B2AC";
          doc.style.color = "white";
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(error);

      });
  };

  const regen = async (prompt) => {
    setLoading(true);
    var regen_data = data;
    var regen_prompt_ = prompt + regen_prompt;
    regen_data["type"] = regen_prompt_;
    axios
      .post("https://34.28.224.150/results", regen_data)
      .then((response) => {
        results.map((n, i) => {
          if (prompts[results[i]["heading"]] === prompt) {
            setResults((prevResults) => {
              const updatedResults = [...prevResults];
              updatedResults[i] = {
                ...updatedResults[i],
                answer: response.data,
              };
              setLoading(false);
              return updatedResults;
            });
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  };

  useEffect(() => {
    const container = document.getElementById("phyc_container");
    const body = document.body;
    setContainerHeight(container.offsetHeight);
  }, [isLoading]);


  return (
    <>

    {isLoading ? 
    <>
        <div style = {{width:"100vw", height:containerHeight, display:"flex", flexDirection:"column", justifyContent : "center", alignItems:"center", backgroundColor:"rgba(0, 0, 0, 0.67)", position:"fixed"}}>
            <CircleLoader color = "#36d7b7"/>
            <p style = {{textAlign:"center", color:"white"}}>Loading ...</p>
        </div>
    </>
     : <></>}
      <NavbarPhyc />
      <div ref={contanerRef} id="phyc_container">
        <div id="phyc_header">
          <div id="phyc_details">
            <div id="doctor_details_phyc">
              <img src={doctor} alt="doctor_image" />
              <p>
                Dr. Rae O. Sonshyne, PhD. I Sonshyne Care Licensed Clinical
                Psychologist, PSY 12345 98765 Sunny Street, San Diego, 65432
                Phone: 555-555-5555 I Fax: 555-555-5555
              </p>
            </div>

            <div id="patient_details_phyc">
              <img src={p1} alt="patient_image" />
              <div id="patient_info_phyc">
                <p>Name: Stephanie Kauras</p>
                <p>Date Of Birth : 21/09/2000</p>
                <p>Age: 12 Years, 2 months</p>
                <p>Primary Language: English</p>
                <p>Education: Primary School</p>
                <p>School: Texas High School</p>
                <p>Gender: Female</p>
                <p>Test Date(s): 24th February, 2024</p>
                <p>Report Date: 1st March, 2024</p>
              </div>
              <div id="patient_buttons_phyc">
                <button>Consents</button>
                <button>Chat</button>
                <button>Video Visit</button>
                <button>Phone Call</button>
              </div>
            </div>
          </div>

          <div id="phyc_types">
            <p id="Reason for Referral">Reason for Referral</p>
            <p id="Types Of Evaluation">Types Of Evaluation</p>
            <p id="Background Information">Background Information</p>
            <p id="Behavioral Observations">Behavioral Observations</p>
            <p id="Test Findings">Test Findings</p>
            <p id="Conclusion">Conclusion</p>
            <p id="Diagnosis">Diagnosis</p>
            <p id="Recommendations">Recommendations</p>
            <p id="Welfie C.A.R.E. Plan">Welfie C.A.R.E. Plan</p>
          </div>
        </div>

        <div onClick={()=>{download_pdf();}} id="generate_report">
          <p>REPORT</p>
          <img src={report} alt="report button" />
        </div>

        {results.map((n, i) => {
          return (
            <>
              <div id="results_phyc">
                <h2>{results[i]["heading"]}</h2>

                {editedContent === results[i]["answer"] && editing ? (
                    <>
                  <textarea id="editableText" 
                    value={updatedEditedContent}
                    onChange={(e) => setUpdatedEditedContent(e.target.value)}
                  />
                    <div >
                      <button id="saveButton"
                        onClick={() => {
                          setResults((prevResults) => {
                            const updatedResults = [...prevResults];
                            updatedResults[i] = {
                              ...updatedResults[i],
                              answer: updatedEditedContent,
                            };
                            return updatedResults;
                          });
                          setEditedContent("");
                          setUpdatedEditedContent("");

                          setEditing(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{ __html: results[i]["answer"] }}
                  ></p>
                )}
              </div>

              <div className = "ctrl" id="controls">
                <input
                  type="text"
                  placeholder="Type here to let me know how we can improve the above"
                  onChange={(e) => {
                    setRegenPrompt(e.target.value);
                  }}
                />
                <div id="panel_phyc">
                  <div
                    onClick={() => {
                      regen(prompts[results[i]["heading"]]);
                    }}
                    id="regenerate"
                  >
                    <img src={regen_} alt="regenerate" />
                    <p>Regenerate</p>
                  </div>

                  <div id="edit_phyc">
                    <img src={edit} alt="edit" />
                    <p
                      onClick={() => {
                        setEditedContent(results[i]["answer"]);
                        setUpdatedEditedContent(results[i]["answer"]);
                        setEditing(true);
                      }}
                    >
                      Edit
                    </p>
                  </div>

                 

                  <div id="add_sources">
                    <img src={add} alt="add" />
                    <p>Add Sources</p>
                  </div>

                  <div id="prompts">
                    <p>Prompts</p>
                  </div>
                </div>
              </div>
            </>
          );
        })}

        <div id="template">
          <p>Click below to select a template</p>
          <div id="template_choice">
            <select
              onChange={(e) => {
                setPrompt(e.target.value);
                console.log(e.target.value);
              }}
            >
              <option value={prompts["Reason for Referral"]}>
                Reason for Refferal
              </option>
              <option value={prompts["Types Of Evaluation"]}>
                Types of Evaluation
              </option>
              <option value={prompts["Background Information"]}>
                Background Information
              </option>
              <option value={prompts["Behavioral Observations"]}>
                Behavioral Observations
              </option>
              <option value={prompts["Test Findings"]}>Test Findings</option>
              <option value={prompts["Conclusion"]}>Conclusion</option>
              <option value={prompts["Diagnosis"]}>Diagnosis</option>
              <option value={prompts["Recommendations"]}>
                Recommendations
              </option>
              <option value={prompts["Welfie C.A.R.E. Plan"]}>
                Welfie C.A.R.E. Plan
              </option>
            </select>

            <button
              onClick={() => {
                get_results();
              }}
            >
              Click here to add this section
            </button>
          </div>

          <p>
            This is an example of the type of text, data, or graphs that will be
            shown in the next section. You can choose different types of
            sections in the drop down bar above
          </p>
        </div>
      </div>
    </>
  );
}

export default Phyc;
