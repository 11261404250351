import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const open_menu = () => {
  const menuButton = document.getElementById("menu");
  menuButton.style.display = "none";

  const menu = document.getElementById("menuItems");
  menu.style.display = "flex";
};

const close_menu = () => {
  const menuButton = document.getElementById("menu");
  menuButton.style.display = "flex";

  const menu = document.getElementById("menuItems");
  menu.style.display = "none";
};

function Navbar() {
  return (
    <>
      <div id="navbar">
        <div id="navLeft">
          <Link id="logo" style={{ textDecoration: "none" }} to="/">
            <h1>Ask Q and A</h1>
          </Link>
        </div>
        <div id="navRight">
          <Link style={{ textDecoration: "none" }} to="/login">
            <p>Login</p>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/signup">
            <p>Signup</p>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/doctor">
            <p>Soap</p>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/phyc">
            <p>Phyc</p>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/genai">
            <p>GenAI</p>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/hero">
            <p>Hero</p>
          </Link>
        </div>
      </div>

      <div
        onClick={() => {
          open_menu();
        }}
        id="menu"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div id="menuItems">
        <Link style={{ textDecoration: "none" }} to="/">
          <p>Home</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/login">
          <p>Login</p>
        </Link>
        <p
          onClick={() => {
            close_menu();
          }}
        >
          Close
        </p>
      </div>
    </>
  );
}

export default Navbar;
