import Doctor from "./Components/Doctor";
import Notes from "./Components/Notes";
import Soap from "./Components/Soap";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Admin from "./Components/Admin";
import CreateAdmin from "./Components/CreateAdmin";
import ViewGTC from "./Components/ViewGTC";
import ViewUsers from "./Components/ViewUsers";
import AuthState from "./Components/context/AuthState";
import PatientState from "./Components/context/PatientState";
import GPTState from "./Components/context/GPTState";
import AddGTC from "./Components/AddGTC";
import Chatbox from "./Components/Chatbox";
import Phyc from "./welfie/src/Components/Phyc";
import Prompt from "./welfie/src/Components/Prompt";
import HeroUserSelect from "./welfie/src/Components/HeroUserSelect";
import HeroRec from "./welfie/src/Components/HeroRec";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroRefUser from "./welfie/src/Components/HeroRefUser";
import HeroRefDoctor from "./welfie/src/Components/HeroRefDoctor";

function App() {
  return (
    <>
      <BrowserRouter>
      <GPTState>
      <PatientState>
      <AuthState>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/chatbox" element={<Chatbox />} />
              <Route path="/doctor" element={<Doctor />} />
              <Route path="/phyc" element={<Phyc />} />
              <Route path="/hero" element={<HeroUserSelect />} />
              <Route path="/herorec" element={<HeroRec />} />
              <Route path="/herorefuser" element={<HeroRefUser />} />
              <Route path="/herorefdoctor" element={<HeroRefDoctor />} />
              <Route path="/genai" element={<Prompt />} />
              <Route path="/notes" element={<Notes />} />
              <Route path="/soap" element={<Soap />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/createadmin" element={<CreateAdmin />} />
              <Route path="/addgtc" element={<AddGTC />} />
              <Route path="/viewgtc" element={<ViewGTC />} />
              <Route path="/viewusers" element={<ViewUsers />} />
          </Routes>
        </AuthState>
        </PatientState>
        </GPTState>
      </BrowserRouter>
    </>
  );
}

export default App;
