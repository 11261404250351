import React , {useState} from "react";
import "./Notes.css";
import hp from "../assets/hp.png";
import letter from "../assets/letter.png";
import parents from "../assets/parents.png";
import pcp from "../assets/pcp.png";
import soap from "../assets/soap.png";
import tutor from "../assets/tutor.png";
import axios from "axios";
import Loader from "./Loader";

import PatientContext from "./context/PatientContext";
import GPTContext from "./context/GPTContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
function Notes()
{
    const patient_data = useContext(PatientContext);
    const gpt_context = useContext(GPTContext);
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigate();

    const invoke_gpt = async ()=>{
        setLoading(true);
        const data = patient_data.state_;
        await axios.post("https://flask-production-5f72.up.railway.app/results", data).then((response)=>{
            const soapNote = response.data;

            const subjectiveIndex = soapNote.indexOf("Subjective:");
            const objectiveIndex = soapNote.indexOf("Objective:");
            const assessmentIndex = soapNote.indexOf("Assessment:");
            const planIndex = soapNote.indexOf("Plan:");

            const subjectivePart = soapNote.substring(subjectiveIndex, objectiveIndex).replace("Subjective:", "").trim();
            const objectivePart = soapNote.substring(objectiveIndex, assessmentIndex).replace("Objective:", "").trim();
            const assessmentPart = soapNote.substring(assessmentIndex, planIndex).replace("Assessment:", "").trim();
            const planPart = soapNote.substring(planIndex).replace("Plan:", "").trim();

            console.log("Subjective:", subjectivePart);
            console.log("Objective:", objectivePart);
            console.log("Assessment:", assessmentPart);
            console.log("Plan:", planPart);

            const local_data = {
                "subjective" : subjectivePart,
                "objective" : objectivePart,
                "assessment" : assessmentPart,
                "plan" : planPart,
            }

            gpt_context.update(local_data);
            setLoading(false);
            navigation("/soap");


        }).catch((error)=>{
            console.log(error);
        })
    }

    return(
        <>
        <div id="patient_info">
            <div id="patient_image">

            </div>

            <div id="details">
                <p>{patient_data.state_["name"]}</p>
                <p>Gender: {patient_data.state_["gender"]} | Age: {patient_data.state_["age"]} | DOB: {patient_data.state_["dob"]} | MRN: {patient_data.state_["MRN"]} | Language: {patient_data.state_["language"]} </p>
                <p>Family Contact: {patient_data.state_["contact"]}</p>
                <p>Physician: {patient_data.state_["physician"]}</p>
            </div>
            <div id="contact">
                <button>Chat</button>
                <button>Video Visit</button>
                <button>Phone Call</button>
            </div>
        </div>

        <div id="choose">
            <h2>Choose which type of note,letter or summary you would like to create.</h2>
            <button onClick={()=>{invoke_gpt();}}>Go</button>
        </div>

        <div id="note_type">
            <div class = "type_div" id="hp">
                <div id="top">
                    <input disabled = {true} type = "radio"/>
                    <p>HISTORY & PHYSICAL</p>
                    <p>Update</p>
                </div>
                <img src = {hp} alt = "hp"/>
                <p>H&P</p>
            </div>

            <div class = "type_div" id="soap">
                <div id="top">
                    <input checked = {true} type = "radio"/>
                    <p>S.O.A.P</p>
                    <p>Update</p>
                </div>
                <img src = {soap} alt = "soap"/>
                <p>Soap Note</p>
            </div>

            <div class = "type_div" id="pcp">
                <div id="top">
                    <input disabled = {true} type = "radio"/>
                    <p>PRIMARY PHYSICIAN</p>
                    <p>Update</p>
                </div>
                <img src = {pcp} alt = "pcp"/>
                <p>PCP</p>
            </div>


            <div class = "type_div" id="letter">
                <div id="top">
                    <input disabled = {true} type = "radio"/>
                    <p>PATIENT</p>
                    <p>Update</p>
                </div>
                <img src = {letter} alt = "notes"/>
                <p>Letter for Littles</p>
            </div>


            <div class = "type_div" id="parent">
                <div id="top">
                    <input disabled = {true} type = "radio"/>
                    <p>PARENTS</p>
                    <p>Update</p>
                </div>
                <img src = {parents} alt = "parent letter"/>
                <p>Parent Letter</p>
            </div>


            <div class = "type_div" id="tutor">
                <div id="top">
                    <input disabled = {true} type = "radio"/>
                    <p>SCHOOL NURSE</p>
                    <p>Update</p>
                </div>
                <img src = {tutor} alt = "notes"/>
                <p>Tutor</p>
            </div>
        </div>
        {isLoading ? <Loader/>: ""}
        </>
    );
}

export default Notes;