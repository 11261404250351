import React, {useState} from "react";
import NavbarPhyc from "./NavbarPhyc";
import "./Prompt.css"
import axios from "axios";
import { CircleLoader } from "react-spinners";

function Prompt()
{
    const [desiredOutput, setDesiredOutput] = useState("");
    const [prompt, setPrompt] = useState("");
    const [result, setResult] = useState("");

    const [saved, setSaved] = useState([]);

    const [isLoading, setLoading] = useState(false);
 

    const get_results = async ()=>{
        setLoading(true);
        const data = {
            desired : desiredOutput,
            prompt : prompt
        }
        console.log(JSON.stringify(data))
        await axios.post ("https://34.28.224.150/genai",JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json',
            },
          } ).then((response)=>{
            console.log(response);
            setResult(response.data);
            const newElement ={
                "output" : desiredOutput,
                "prompt" : prompt,
                "result" : response.data
            }
            setSaved(prevArray => [...prevArray, newElement]);
            console.log(response.data);
        }).catch((error)=>{
            alert(error);
        })
        setLoading(false);

    }

    return(
        <>

            {isLoading ? 
            <>
                <div style = {{width:"100vw", height:"100vh", display:"flex", flexDirection:"column", justifyContent : "center", alignItems:"center", backgroundColor:"rgba(0, 0, 0, 0.67)", position:"fixed"}}>
                    <CircleLoader color = "#36d7b7"/>
                    <p style = {{textAlign:"center", color:"white"}}>Loading ...</p>
                </div>
            </>
            : <></>}
        <NavbarPhyc/>
        <div id="prompt_container">
            <div id = "prompt_menu">

                <div class="menu_options">
                    <p>Provider Type</p>
                    <select>
                        <option value = "tutor">Welfie Tutor</option>
                        <option value = "coach">Welfie Coach</option>
                        <option value = "care">Welfie Care</option>
                    </select>
                </div>

                <div class="menu_options">
                    <p>Provider Type</p>
                    <select>
                        <option value = "family">Family Physician</option>
                        <option value = "ed">E.D. Doc</option>
                    </select>
                </div>

                <div class="menu_options">
                    <p>Note Type</p>
                    <select>
                        <option value = "phyc">Phyc Assessment</option>
                        <option value = "soap">Soap</option>
                    </select>
                </div>

                <div class="menu_options">
                    <p>Section Type</p>
                    <select>
                        <option value = "subjective">Subjective</option>
                        <option value = "objective">Objecive</option>
                        <option value = "assessment">Assessment</option>
                        <option value = "plan">Plan</option>
                    </select>
                </div>

            </div>

            <div id="input_area">

                <div>
                    <p>Ask Welfie LLM</p>
                    <p dangerouslySetInnerHTML={{ __html: result }}></p>
                    <button>Save</button>
                </div>

                <div>
                    <p>Desired Output</p>
                    <input onChange={(e)=>{setDesiredOutput(e.target.value);}} type = "text" placeholder="Enter the desired output"/>
                    <button>Set</button>
                </div>

                <div>
                    <p>Enter Prompt</p>
                    <input onChange={(e)=>{setPrompt(e.target.value);}} type = "text" placeholder="Enter the prompt for AskWelfie LLM to achieve output"/>
                    <button onClick={()=>{get_results();}}>Submit</button>
                </div>


            </div>

            <div id="stored_results">
                <ul>
                    {saved.map((n,i)=>{
                        return(
                        <li>
                        <h2>Desired Output</h2>
                        <p>{saved[i]["output"]}</p>
                        <h2>Prompt</h2>
                        <p>{saved[i]["prompt"]}</p>
                        <h2>Results</h2>
                        <p>{saved[i]["result"]}</p>
                        </li>
                        )
                    })}

                </ul>
            </div>
        </div>
        </>
    );
};

export default Prompt;
