    import React , {useState, useEffect} from "react";
    import "./Chatbox.css";
    import ChatArea from "./ChatArea";
    import NavbarUser from "./NavbarUser";
    import { db } from "../firebase";
    import { collection, getDocs } from "firebase/firestore";
    import Loader from "./Loader";
    import axios from "axios";
    import { useContext } from "react";
    import AuthContext from "./context/AuthContext";
    import { useNavigate } from "react-router-dom";

    function Chatbox()
    {
        const [companyNames, setCompanyNames] = useState([]);
        const [productNames, setProductNames] = useState([]);
        const [docNames , setDocNames] = useState([]);
        const [gtcLinks, setGtcLinks] = useState([]);
        const [isLoading, setLoading] = useState(false);

        const [currentIndex, setCurrentIndex] = useState(null);
        const [currentProductIndex, setCurrentProductIndex] = useState(null);
        const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);

        const [currentIndex_m, setCurrentIndex_m] = useState(null);
        const [currentProductIndex_m, setCurrentProductIndex_m] = useState(null);
        const [currentDocumentIndex_m, setCurrentDocumentIndex_m] = useState(null);

        const [companyDisplay, setCompanyDisplay] = useState(true);
        const [docDisplay, setDocDisplay] = useState(true);

        const [chatBlur, setChatBlur] = useState("blur(5px)");
        const [chatDisbaled, setDisabled] = useState(true);

        const auth_verif = useContext(AuthContext);
        const navigation = useNavigate();





        const get_company_names = async ()=>{

            setLoading(true);
            setCompanyDisplay(false);
            console.log("company : ",companyDisplay);

            const doc = document.getElementById("chatboxContainer");
            doc.style.filter = "blur(50px)";

            const gtcCollections = collection(db, "gtcDetails");
            const gtcDocs = await getDocs(gtcCollections);

            var company_names = [];

            gtcDocs.forEach((doc)=>{

                if(doc.data()["product_name"] === productNames[currentIndex])
                {
                    console.log(productNames);
                    company_names.push(doc.data()["company_name"]);
                }
            })

            setCompanyNames(company_names);

            setCurrentIndex_m(currentIndex);
            setCurrentIndex(null);

            setLoading(false);
            doc.style.filter = "blur(0px)";


        }

        const get_product_names = async ()=>{
            setLoading(true);

            const gtcCollections = collection(db, "gtcDetails");
            const gtcDocs = await getDocs(gtcCollections);

            var product_names = [];

            gtcDocs.forEach((doc)=>{

                product_names.push(doc.data()["product_name"]);
 
            })

            setProductNames(product_names);


            setLoading(false);


        }

        const get_gtc_names = async ()=>{
            setLoading(true);

            setDocDisplay(false);
            const gtcCollections = collection(db, "gtcDetails");
            const gtcDocs = await getDocs(gtcCollections);

            var gtc_names = [];

            gtcDocs.forEach((doc)=>{
                if(doc.data()["company_name"] === companyNames[currentProductIndex])
                {
                    gtc_names.push(doc.data()["document_name"]);
                }
            })

            setDocNames(gtc_names);
            setCurrentProductIndex_m(currentProductIndex);
            setCurrentProductIndex(null);

            setLoading(false);
        }
        const load_model = async ()=>{
            setLoading(true);
            const gtcCollections = collection(db, "gtcDetails");
            const gtcDocs = await getDocs(gtcCollections);


            var gtc_link;
            var gtc_text;

            gtcDocs.forEach((doc)=>{
                if(doc.data()["product_name"] === productNames[currentIndex_m] && doc.data()["company_name"] === companyNames[currentProductIndex_m])
                {
                    gtc_link = doc.data()["pdf"];
                    console.log("match");
                }
            })

            const data = {link : gtc_link};

            try {
                console.log(data);
                const response = await axios.post('https://flask-production-5903.up.railway.app/embeddings', data); 
                // Handle the response data
                console.log(response.data);
            } catch (error) {
                // Handle errors
                console.error('Error:', error);
            }
            


            setChatBlur("blur(0px)");
            setDisabled(false);
            setLoading(false);
        }

        useEffect(()=>{

            get_product_names();
            console.log(currentIndex);
            if(currentIndex != null)
            {
                get_company_names();

            }

            if(currentProductIndex != null)
            {
                get_gtc_names();
            }
        },[currentIndex, currentProductIndex])


        if(auth_verif.state['login_user'] === false)
        {
            navigation("/login");
        }
        return(
            <>
            <NavbarUser type = "chatbox"/>
            <div id="chatboxContainer">
                <div id="chatboxContent">
                    <div id="chatHeading">
                        <h1>Chat with your own data</h1>
                        <p></p>                    
                    </div>

                    <div id="chatboxDiv">




                        <div id="options">

                            <select onChange={(e)=>{setCompanyNames([]); setDocNames([]); setCurrentIndex(Number(e.target.value));}}>
                            <option>Select a Department</option>
                                {productNames.map((el,i)=>{
                                    return (<option value = {i}>{el}</option>)
                                })}
                            </select>

                            <select disabled = {companyDisplay}  onChange={(e)=>{ setCurrentProductIndex(Number(e.target.value)); }} id="company">
                                <option value = "null">Select a Product</option>
                                {companyNames.map((el,i)=>{
                                    return (<option value = {i}>{el}</option>)
                                })}
                            </select>
        

                            <button onClick={()=>{load_model()}} id="load">Load Model</button>
                        </div>

                        <div style = {{filter : chatBlur}} id="chatbox">
                            <ChatArea disable = {chatDisbaled}/>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <Loader/>:""}
            </>
        )
    }

    export default Chatbox;