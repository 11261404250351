import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import {auth} from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";

const open_menu = ()=>{
    const menuButton = document.getElementById("menu");
    menuButton.style.display = "none";

    const menu = document.getElementById("menuItems");
    menu.style.display = "flex";
}

const close_menu = ()=>{
    const menuButton = document.getElementById("menu");
    menuButton.style.display = "flex";

    const menu = document.getElementById("menuItems");
    menu.style.display = "none";
}




function NavbarUser()
{

    const navigation = useNavigate();
    const auth_verif = useContext(AuthContext);

    return(
        <>
        <div id="navbar">
            <div id="navLeft">
                <Link id="logo" style={{ textDecoration: 'none' }} to = "/chatbox"><h1>Ask Q and A</h1></Link>
            </div>
            <div id="navRight">
                <Link onClick={()=>{auth_verif.update("user", false); navigation("/login")}} to = "/login" style={{ textDecoration: 'none' }}><p>Log Out</p></Link>
                
            </div>
        </div>

        <div onClick={()=>{open_menu()}} id="menu">
                <div></div>
                <div></div>
                <div></div>
        </div>

        <div id="menuItems">
            <Link style={{ textDecoration: 'none' }} to ="/"><p>Home</p></Link>
            <Link onClick={()=>{navigation("/")}} style={{ textDecoration: 'none' }}><p>Logout</p></Link>
            <Link style={{ textDecoration: 'none' }} to ="/admin"><p>Admin</p></Link>
            <p onClick={()=>{close_menu()}}>Close</p>
        </div>
        </>
    );
}

export default NavbarUser;