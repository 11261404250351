import React, {useState} from "react";
import "./Login.css"
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../firebase";
import Loader from "./Loader";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
function Login()
{
    const auth_verif = useContext(AuthContext);
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const[user, setUser] = useState();

    const sign_in = ()=>{
        setLoading(true);
        const doc = document.getElementById("loginContainer");
        doc.style.filter = "blur(50px)";
        signInWithEmailAndPassword(auth, email, password).then((userCredentials)=>{auth_verif.update("user",true);setUser(userCredentials);console.log(user);setLoading(false); doc.style.filter = "blur(0px)";navigation("/chatbox")}).catch((error)=>{setLoading(false);doc.style.filter = "blur(0px)";alert("Invalid Login/Password")});


    }
    
    return(
        <>
            <Navbar/>
            <div id="loginContainer">
                <div id="loginForm">
                    <h1>Login</h1>
                    <input type = "text" placeholder="Email Address" onChange={(e)=>{setEmail(e.target.value)}}/>
                    <input type = "password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                    <p>Don't have an account ?<Link to ="/signup" style = {{textDecoration:"none"}}><p> Sign Up</p></Link></p>
                    <button onClick={()=>{sign_in()}}>Login</button>
                </div>
            </div>
            {isLoading ? <Loader/>: ""}
        </>
    );
}

export default Login;