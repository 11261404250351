import React, {useState, useEffect} from "react";
import "./CreateAdmin.css";
import AdminNav from "./AdminNav";
import { authAdmin } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "./Loader";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
import { useNavigate } from "react-router-dom";

function CreateAdmin()
{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const auth_verif = useContext(AuthContext);
    const navigation = useNavigate();

    useEffect(()=>{
        if(auth_verif.state["login_admin"] === false)
        {
            navigation("/admin");
        }
    },[auth_verif, navigation])



    const sign_up = ()=>{
        setLoading(true);
        const doc = document.getElementById("createAdminContainer");
        doc.style.filter = "blur(50px)";
        createUserWithEmailAndPassword(authAdmin, email, password).then((user)=>{alert("Admin Created Successfuly"); setLoading(false);doc.style.filter = "blur(0px)";}).catch((error)=>{alert(error);setLoading(false);doc.style.filter = "blur(0px)"})
    }



    return(
        <>
        <AdminNav/>
        <div id="createAdminContainer">
            <div id="createAdminForm">
                <h1>Admin Panel</h1>
                <p>Create new admin user</p>
                <input type = "text" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
                <input type = "password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}/>
                <button onClick={()=>{sign_up()}}>Create</button>
            </div>
        </div>
        {isLoading ? <Loader/> : ""}
        </>
    );
}

export default CreateAdmin;