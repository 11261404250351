import React from "react";
import { RadialBarChart, RadialBar} from 'recharts';
function Pie(props)
{
    return(
        <>
            <RadialBarChart   width = {200} height = {200} cx="50%" cy="50%" innerRadius="10%" outerRadius="80%" barSize={10} data={props.data}>
                <RadialBar
                    minAngle={15}
                    background
                    clockWise
                    dataKey="uv"
                />
            </RadialBarChart>
            <p>{props.score}/10</p>
        </>
    );
}

export default Pie;