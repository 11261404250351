import React, {useState} from "react";
import "./Doctor.css";
import Pie from "./Pie";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import PatientContext from "./context/PatientContext";
function Doctor()
{
    const patient_data = useContext(PatientContext);

    const navigation = useNavigate();

    const [patient_name, set_patient_name] = useState("John Smith");


    const patients = [
        {
            "name": "John Smith",
            "gender" : "Male",
            "MRN" : "12345678",
            "language" : "English",
            "contact" : "Larry Smith (Father)",
            "physician": "Surbhi Bhargava, M.D",
            "mental" : "8",
            "sleep" : "8",
            "mood" : "8",
            "food" : "8",
            "stress" : "8",
            "anxiety" : "3",
            "dob": "14/2009",
            "age" : "13"
        },
        {
            "name": "Lucia Garcia",
            "gender" : "Female",
            "MRN" : "345671234",
            "language" : "Spanish",
            "contact" : "Martin Garcia(Father)",
            "physician": "Mathew R. Hernandez, M.D.",
            "mental" : "4",
            "sleep" : "6",
            "mood" : "5",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "15/2008",
            "age" : "14"
        },
        {
            "name": "Stephanie  Kim",
            "gender" : "Female",
            "MRN" : "67890123",
            "language" : "English",
            "contact" : "Mady Kim (Mother)",
            "physician": "Luis A. Folan, M.D.",
            "mental" : "6",
            "sleep" : "5",
            "mood" : "4",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "11/2013",
            "age" : "11"
        },

        {
            "name": "Derek vo",
            "gender" : "Male",
            "MRN" : "87654321",
            "language" : "Vietnamese ",
            "contact" : "Valeria Vo (Mother)",
            "physician": "Christina T. Devine, M.D.",
            "mental" : "6",
            "sleep" : "4",
            "mood" : "5",
            "food" : "5",
            "stress" : "4",
            "anxiety" : "4",
            "dob" : "12/2014",
            "age" : "10"
        }
    ]

    const [data_mental, set_data_mental] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])

      const [data_sleep, set_data_sleep] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])


      const [data_mood, set_data_mood] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])

      const [data_food, set_data_food] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])

      const [data_stress, set_data_stress] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])

      const [data_anxiety, set_data_anxiety] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ])


      const handle_data = (name)=>{

        patients.map((n,i)=>{
            if(name === patients[i]["name"])
            {
                set_data_mental(data_mental.map((item, index) => 
                index === 1 ? { ...item, uv: patients[i]["mental"] } : item
              ));
              
                set_data_sleep(data_sleep.map((item, index) => 
                index === 1 ? { ...item, uv: patients[i]["sleep"] } : item
                ));
        
                set_data_mood(data_mood.map((item, index) => 
                index === 1 ? { ...item, uv: patients[i]["mood"] } : item
            ));
            
                set_data_food(data_food.map((item, index) => 
                index === 1 ? { ...item, uv: patients[i]["food"] } : item
                ));
        
                set_data_anxiety(data_anxiety.map((item, index) => 
                index === 1 ? { ...item, uv: patients[i]["anxiety"] } : item
            ));
        
            set_data_stress(data_stress.map((item, index) => 
            index === 1 ? { ...item, uv: patients[i]["stress"] } : item
            ));

            }
        })

      }

      const [mental, set_mental] = useState(false);
      const [mood, set_mood] = useState(false);
      const [food, set_food] = useState(false);
      const [stress, set_stress] = useState(false);
      const [anxiety, set_anxiety] = useState(false);
      const [sleep, set_sleep] = useState(false);

      const handle_radio = (id)=>{
        const radio = document.getElementById(id);
        var value;
        if(radio.checked === false)
        {
            radio.checked = true;
            value = true;
        }
        else
        {
            radio.checked = false;
            value = false;
        }

        if(id === "mental")
        {
            set_mental(value);
        }
        if(id === "mood_")
        {
            set_mood(value);
        }
        if(id === "food_")
        {
            set_food(value);
        }
        if(id === "sleep_")
        {
            set_sleep(value);
        }
        if(id === "stress_")
        {
            set_stress(value);
        }
        if(id === "anxiety_")
        {
            set_anxiety(value);
        }


      }

      const update_context = ()=>{
        var data;

        patients.map((n,i)=>{
            if(patients[i]["name"] === patient_name)
            {
                data = {
                    "name": patients[i]["name"],
                    "gender" : patients[i]["gender"],
                    "MRN" : patients[i]["MRN"],
                    "language" : patients[i]["language"],
                    "contact" : patients[i]["contact"],
                    "physician": patients[i]["physician"],
                    "mental" : "Not_Specified",
                    "sleep" : "Not_Specified",
                    "mood" : "Not_Specified",
                    "food" : "Not_Specified",
                    "stress" : "Not_Specified",
                    "anxiety" : "Not_Specified",
                    "dob" : patients[i]["dob"],
                    "age" : patients[i]["age"]
                }

                return true;
            }


        })

        if(mental === true)
        {
            data["mental"] = data_mental[1]["uv"];
        }
        if(sleep === true)
        {
            data["sleep"] = data_sleep[1]["uv"];
        }
        if(mood === true)
        {
            data["mood"] = data_mood[1]["uv"];
        }
        if(food === true)
        {
            data["food"] = data_food[1]["uv"];
        }
        if(stress === true)
        {
            data["stress"] = data_stress[1]["uv"];
        }
        if(anxiety === true)
        {
            data["anxiety"] = data_anxiety[1]["uv"];
        }

        patient_data.update_patients(data);

      }
      


    return (
        <>
        <div id = "select_patient">
            <h2>Select Patient</h2>
            <select onChange={(e)=>{set_patient_name(e.target.value); handle_data(e.target.value)}}>
                {
                    patients.map((n,i)=>{
                        return(<><option value = {patients[i]["name"]}>{patients[i]["name"]}</option></>)
                    })
                }
            </select>
        </div>

        {
            patients.map((n,i)=>{
                if(patients[i]["name"] === patient_name)
                {
                    return (
                        <>
                        <div id="patient_info">
                            <div id="patient_image">

                            </div>

                            <div id="details">
                                <p>{patients[i]["name"]}</p>
                                <p>Gender: {patients[i]["gender"]} | Age: {patients[i]["age"]} | DOB: {patients[i]["dob"]} | MRN: {patients[i]["MRN"]} | Language: {patients[i]["language"]} </p>
                                <p>Family Contact: {patients[i]["contact"]}</p>
                                <p>Physician: {patients[i]["physician"]}</p>
                            </div>
                            <div id="contact">
                                <button>Chat</button>
                                <button>Video Visit</button>
                                <button>Phone Call</button>
                            </div>
                        </div>
                        </>
                    )
                }
            })
        }


        <div id="choose">
            <h2>Choose which domains you want to summarise, then click on Go</h2>
            <button onClick={()=>{update_context(); navigation("/notes")}}>Go</button>
        </div>

        <div id="scores">
            <div onClick={()=>{handle_radio("mental");}} class="score_divs" id="mental_health">
                <div id="top">
                    <input id = "mental" type = "radio"/>
                    <p>Mental Health</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_mental} score = {data_mental[1]["uv"]}/>
                <p id = "info_p">Social Emotional Learning and Mental Health</p>
            </div>

            <div onClick={()=>{handle_radio("sleep_");}}  class="score_divs" id="sleep">
                <div id="top">
                    <input id = "sleep_" type = "radio"/>
                    <p>Sleep</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_sleep} score = {data_sleep[1]["uv"]}/>
                <p id = "info_p">Track you sleep schedule and how it affects your health.</p>
            </div>

            <div onClick={()=>{handle_radio("food_");}} class="score_divs" id="food">
                <div id="top">
                    <input id = "food_" type = "radio"/>
                    <p>Food</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_food} score = {data_food[1]["uv"]}/>
                <p id = "info_p">Analyse your diet and its affect on your health.</p>
            </div>

            <div onClick={()=>{handle_radio("stress_");}} class="score_divs" id="stress">
                <div id="top">
                    <input id = "stress_" type = "radio"/>
                    <p>Stress</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_stress} score = {data_food[1]["uv"]}/>
                <p id = "info_p">Track your stress levels and reduce it</p>
            </div>

            <div onClick={()=>{handle_radio("anxiety_");}} class="score_divs" id="anxiety">
                <div id="top">
                    <input id = "anxiety_" type = "radio"/>
                    <p>Anxiety</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_anxiety} score = {data_anxiety[1]["uv"]}/>
                <p id = "info_p">Understand your anxiety and make better decisions</p>
            </div>

            <div onClick={()=>{handle_radio("mood_");}} class="score_divs" id="mood">
                <div id="top">
                    <input id = "mood_" type = "radio"/>
                    <p>Mood</p>
                    <p>Update</p>
                </div>
                <Pie data = {data_mood} score = {data_mood[1]["uv"]}/>
                <p id = "info_p">Track your mood and see how it affects your daily life.</p>
            </div>
        </div>

  

        </>
    )
}


export default Doctor;