import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import patientImage from "../assets/item-photo.png";
import "./HeroRec.css";
import Pie from "../../../Components/Pie";
import cardImage from "../assets/football.png";
import c1 from "../assets/c2.jpeg";
import c2 from "../assets/c3.jpeg";

import rec from "../assets/recommend.png";
import ref from "../assets/ref.png";

import stars from "../assets/stars.png";
import { CircleLoader } from "react-spinners";
import axios from "axios";
import m1 from "../assets/m1.jpeg";
import m2 from "../assets/m2.jpeg";
import m3 from "../assets/m3.png";

function HeroRec()
{
    const location = useLocation();
    const PatientName = location.state;
    const navigation = useNavigate();
    const [recommendations, setRecommendations] = useState([]);
    const [suggested, setSuggested] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [patientImages, setPatientImages] = useState([patientImage, c1, c2]);

    useEffect(()=>{

        setImages([m1,m2,m3]);
        setIsLoading(true);
        var index;
        patients.map((n,i)=>{
            if(n.name === PatientName)
            {
                index = i+1;
                return;
            }
        });

        const data = {
            "index" : index
        }
        //https://flask-production-4198.up.railway.app/recommend

        axios.post("https://flask-production-4198.up.railway.app/recommend", data).then((response)=>{
            setRecommendations(response.data["recommended"]);
            console.log(response.data["recommended"].length);
            if(response.data["recommended"].length < 1)
            {
                setDisplayResources(response.data["suggested"]);   
            }
            setDisplayResources(response.data["recommended"]);
            setSuggested(response.data["suggested"]);
            setIsLoading(false);
            console.log(response.data)
        }).catch((error)=>{
            console.error(error);
            setIsLoading(false);
        });
    },[])


    const patients = [
        {
            "name": "John Smith",
            "gender" : "Male",
            "MRN" : "12345678",
            "language" : "English",
            "contact" : "Larry Smith (Father)",
            "physician": "Surbhi Bhargava, M.D",
            "mental" : "8",
            "sleep" : "8",
            "mood" : "8",
            "food" : "8",
            "stress" : "8",
            "anxiety" : "3",
            "dob": "14/2009",
            "age" : "13"
        },
        {
            "name": "Lucia Garcia",
            "gender" : "Female",
            "MRN" : "345671234",
            "language" : "Spanish",
            "contact" : "Martin Garcia(Father)",
            "physician": "Mathew R. Hernandez, M.D.",
            "mental" : "4",
            "sleep" : "6",
            "mood" : "5",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "15/2008",
            "age" : "14"
        },
        {
            "name": "Stephanie Kim",
            "gender" : "Female",
            "MRN" : "67890123",
            "language" : "English",
            "contact" : "Mady Kim (Mother)",
            "physician": "Luis A. Folan, M.D.",
            "mental" : "6",
            "sleep" : "5",
            "mood" : "4",
            "food" : "8",
            "stress" : "5",
            "anxiety" : "5",
            "dob" : "11/2013",
            "age" : "11"
        },

        {
            "name": "Derek vo",
            "gender" : "Male",
            "MRN" : "87654321",
            "language" : "Vietnamese ",
            "contact" : "Valeria Vo (Mother)",
            "physician": "Christina T. Devine, M.D.",
            "mental" : "6",
            "sleep" : "4",
            "mood" : "5",
            "food" : "5",
            "stress" : "4",
            "anxiety" : "4",
            "dob" : "12/2014",
            "age" : "10"
        }
    ];


    const [data_mental, set_data_mental] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#FFFF00',
        },
        {
            name: '18-24',
            uv: 5,
            pv: 10,
            fill: '#FFFF00',
          },
      ]);

    const [data_anxiety, set_data_anxiety] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#FF0000',
        },
        {
            name: '18-24',
            uv: 4,
            pv: 10,
            fill: '#FF0000',
          },
      ]);


      const [data_food, set_data_food] = useState([
        {
          name: '18-24',
          uv: 10,
          pv: 10,
          fill: '#38b2ac',
        },
        {
            name: '18-24',
            uv: 8,
            pv: 10,
            fill: '#38b2ac',
          },
      ]);

      function extractURL(str) {
        const regex = /(https?:\/\/[^\s]+)/g;
        const match = str.match(regex);
        return match ? match[0] : null;
      }

      const [displayResources, setDisplayResources] = useState(recommendations);


      const handleRecommended = ()=>{
        setDisplayResources(recommendations);
      }

      const handleSuggested = ()=>{
        setDisplayResources(suggested);
      }
    //   useEffect(()=>{
    //     console.log("test");
    //   },[displayResources]);









    return(

        
        <>
        {isLoading ? 
        <>
            <div style = {{width:"100vw", height:"100vh", display:"flex", flexDirection:"column", justifyContent : "center", alignItems:"center", backgroundColor:"rgba(0, 0, 0, 0.67)", position:"fixed"}}>
                <CircleLoader color = "#36d7b7"/>
                <p style = {{textAlign:"center", color:"white"}}>Loading ...</p>
            </div>
        </>
         : <></>}

        <div id="hero-rec-container">
            <div id="hero-rec-banner">
                <p>STEM Elementary</p>
            </div>

            {
                patients.map((n,i)=>{
                    if(patients[i].name === PatientName)
                    {
                        return(
                            <div key={i} id="hero-patient-card">
                                <img src = {patientImages[i]} alt = "patient"/>
                                <div id = "patient-card-subsection1">
                                    <p>{patients[i]["name"]}</p>
                                    <p>Gender: {patients[i]["gender"]} | Age: {patients[i]["age"]} | DOB: {patients[i]["dob"]} | MRN: {patients[i]["MRN"]} | Language: {patients[i]["language"]} </p>
                                    <p>Family Contact: {patients[i]["contact"]}</p>
                                    <p>Physician: {patients[i]["physician"]}</p>
                                </div>
    
                                <div id = "patient-card-buttons">
                                    <button onClick={()=>{navigation("/doctor");}}>SOAP Note</button>
                                    <button onClick={()=>{navigation("/phyc");}}>Write Report</button>
                                </div>
                            </div>
                        )
                    }

                })
            }

            <div id = "hero-rec-stats">
                <div className="stats-box">
                    <Pie data = {data_mental} score = {data_mental[1]["uv"]}/>
                    <p>Mental Health</p>
                </div>

                <div className="stats-box">
                    <Pie data = {data_anxiety} score = {data_anxiety[1]["uv"]}/>
                    <p>Anxiety</p>
                </div>

                <div className="stats-box">
                    <Pie data = {data_food} score = {data_food[1]["uv"]}/>
                    <p>Food</p>
                </div>
            </div>

            <div id="hero-subcontainer">
                {/* <div id="hero-filters">
                    <p>Health Filters</p>
                    <div class = "filter">
                        <label>All</label>
                        <input type = "radio" />
                    </div>

                    <div class = "filter">
                        <label>Mental Health</label>
                        <input type = "radio" />
                    </div>

                    <div class = "filter">
                        <label>Immune Health</label>
                        <input type = "radio" />
                    </div>

                    <div class = "filter">
                        <label>Physical Health</label>
                        <input type = "radio" />
                    </div>

                    <p>Equity Filters</p>

                    <p>Location</p>
                    <div class = "filter">
                        <label>Online</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>In My Community</label>
                        <input type = "radio" />
                    </div>

                    <p>Affordability</p>
                    <div class = "filter">
                        <label>$</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>$$</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>$$$</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>$$$$</label>
                        <input type = "radio" />
                    </div>

                    <p>Insurance</p>
                    <div class = "filter">
                        <label>Blue Cross Blue Shield</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Medicaid</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Medicare</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Antibody</label>
                        <input type = "radio" />
                    </div>

                    <p>Test Proof</p>
                    <div class = "filter">
                        <label>Verified Test</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Unverified Test</label>
                        <input type = "radio" />
                    </div>

                    <p>Vaccine Tracker</p>
                    
                    <p>Vaccine Status</p>
                    <div class = "filter">
                        <label>Vaccinated</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Not Vaccinated</label>
                        <input type = "radio" />
                    </div>

                    <p>Exemptions</p>
                    <div class = "filter">
                        <label>Granted - Medical</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Granted - Physical</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Requested</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Denied</label>
                        <input type = "radio" />
                    </div>


                    <p>Vaccine Dose</p>
                    <div class = "filter">
                        <label>No Dose</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>First Dose</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Second Dose</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Third Dose</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Booster</label>
                        <input type = "radio" />
                    </div>


                    <p>Vaccine Type</p>
                    <div class = "filter">
                        <label>Pfizer</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Moderna</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Johnson & Johnson</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Aztra Zeneca</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Sputnic</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Other</label>
                        <input type = "radio" />
                    </div>

                    <p>Vaccine Proof</p>
                    <div class = "filter">
                        <label>Verified Vaccine</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Unverified Vaccine</label>
                        <input type = "radio" />
                    </div>

                    <p>Symptom Tracker</p>

                    <p>Screen Status</p>
                    <div class = "filter">
                        <label>Positive - Symptoms</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Negative - No Symptoms</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Unscreened</label>
                        <input type = "radio" />
                    </div>

                    <p>Location Tracker</p>

                    <p>Testing</p>
                    <div class = "filter">
                        <label>Daily - 7 Days</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Once a week</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>Twice a week</label>
                        <input type = "radio" />
                    </div>

                    <p>Quarantine</p>
                    <div class = "filter">
                        <label>Modifies</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>14 Days</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>10 Days</label>
                        <input type = "radio" />
                    </div>
                    <div class = "filter">
                        <label>8 Days</label>
                        <input type = "radio" />
                    </div>






                </div> */}
                <div id="hero-recommendations">
                    <p>Recommendations</p>
                    <div id="recommendation-type">
                        <button onClick={()=>{handleRecommended();}}>Match</button>
                    </div>

                    {
                        displayResources && displayResources.map((n,i)=>{
                            return(
                                <>
                                <div key={i} id="hero-rec-card">
                                    <img src = {images[i]} alt = "card"/>
                                    <div id="hero-rec-card-subsection">
                                        <div className="subsection-rows">
                                            <h1 onClick={()=>{
                                                window.location.href = n.url;
                                            }} >{n.name}</h1>
                                            <div className="icons">
                                                <img onClick={()=>{navigation("/herorefuser" , {state : displayResources[i]})}} src = {rec} alt = "recommendation"/>
                                                <img onClick={()=>{navigation("/herorefdoctor" , {state : displayResources[i]})}} src = {ref} alt = "refferal"/>
                                            </div>
                                        </div>
                                        <div className="subsection-rows">
                                            <p>{n.name} is the best place for your improvement, based on the stats provided, our system came up with this recommendation.</p>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        })
                    }

                </div>
            </div>




        </div>
        </>
    );
};

export default HeroRec;