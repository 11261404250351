import React, {useState, useEffect} from "react";
import AdminNav from "./AdminNav";
import "./AddGTC.css";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import {storage} from "../firebase";
import {uploadBytes, ref, getDownloadURL } from "firebase/storage";
import Loader from "./Loader";
import { useContext } from "react";
import AuthContext from "./context/AuthContext";
import { useNavigate } from "react-router-dom";
function AddGTC()
{
    const [docName, setDocName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const navigation = useNavigate();
    const auth_verif = useContext(AuthContext);

    useEffect(()=>{
        if(auth_verif.state["login_admin"] === false)
        {
            navigation("/admin");
        }
    },[auth_verif, navigation])

    const upload_gtc = async ()=>{
        setLoading(true);
        const doc = document.getElementById("addgtcContainer");
        doc.style.filter = "blur(50px)";


        const gtcCollection = collection(db, "gtcDetails");
        var fileInput = document.getElementById("pdfInput");
        var file = fileInput.files[0];

        var fileName = file.name;
    
        const storageRef = ref(storage, fileName);

        
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!');
          getDownloadURL(ref(storage, fileName)).then((url)=>{

            const data = {
                company_name : companyName,
                product_name : docName,
                document_name : docName,
                pdf : url
    
            }

            addDoc(gtcCollection, data).then((e)=>{
                alert("GTC Uploaded");
                setLoading(false);
                doc.style.filter = "blur(0px)";
            }).catch((error)=>{
                alert(error);
                doc.style.filter = "blur(0px)";
    
            })

          })

        });
     
    }



    return(
        <>
        <AdminNav/>
        <div id="addgtcContainer">
            <div id = "addgtcForm">
                <h1>Admin Panel</h1>
                <p>Add new Records</p>
                <input type = "text" placeholder="Department Name" onChange={(e)=>{setDocName(e.target.value)}}/>
                <input type = "text" placeholder = "Product Name" onChange={(e)=>{setCompanyName(e.target.value)}}/>
                <div id="gtcFile">
                    <input id="pdfInput" type = "file"/>
                </div>
                <button onClick={()=>{upload_gtc()}}>Add Record</button>
            </div>
        </div>
        {isLoading ? <Loader/> : ""}
        </>
    );
}

export default AddGTC;