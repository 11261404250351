import React, {useState} from "react";
import "./HeroRefDoctor.css";
import { useLocation } from "react-router-dom";
import cardImage from "../assets/football.png";
import stars from "../assets/stars.png";
import { CircleLoader } from "react-spinners";
function HeroRefDoctor()
{
    const location = useLocation();
    const recommendations = location.state;
    const [email, setEmail] = useState("");
    const  [isLoading, setIsLoading] = useState(false);
    const [studentName, setStudentName] = useState("");
    const [dateRefferal, setDateRefferal] = useState("");
    const [currentSchool, setCurrentSchool] = useState("");
    const [dob, setDob] = useState("");
    const [ethnicity, setEthnicity] = useState("");
    const [gender, setGender] = useState("");
    const [insuranceType, setInsuranceType] = useState("");
    const [guardianName, setGuardianName] = useState("");
    const [address, setAddress] = useState("");
    const [cell, setCell] = useState("");
    const [teacher, setTeacher] = useState("");
    const [iep, setIep] = useState("");
    const [bh, setBh] = useState("");
    const [consent, setConsent] = useState("");


    const send_email = ()=>{

        const emailBody = `
        <h2>Recommendation Name</h2>
        <p>${recommendations.name}</p>
      
        <h2>Recommendation Link</h2>
        <p><a href="${recommendations.url}">${recommendations.url}</a></p>
      
        <h2>Student Details</h2>
        <p><strong>Student Name:</strong> ${studentName}</p>
        <p><strong>Date of Referral:</strong> ${dateRefferal}</p>
        <p><strong>Current School:</strong> ${currentSchool}</p>
        <p><strong>Date of Birth:</strong> ${dob}</p>
        <p><strong>Gender:</strong> ${gender}</p>
        <p><strong>Ethnicity:</strong> ${ethnicity}</p>
        <p><strong>Insurance Type:</strong> ${insuranceType}</p>
        <p><strong>Guardian Name:</strong> ${guardianName}</p>
        <p><strong>Address:</strong> ${address}</p>
        <p><strong>Cell Number:</strong> ${cell}</p>
        <p><strong>Teacher/Grade:</strong> ${teacher}</p>
        <p><strong>IEP:</strong> ${iep}</p>
        <p><strong>BH:</strong> ${bh}</p>
        <p><strong>Consent:</strong> ${consent}</p>
      `;

      

        setIsLoading(true);
        console.log(email)
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer sk_e82dc80705a378f18d1cd7ed3d1e82a01a5131571c71cf51`, // Replace 'token' with your actual authorization token
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            to: email,
            subject: recommendations.name,
            body: emailBody,
            subscribed: true,
            name: "Hero"
          })
        };
        
        fetch('https://api.useplunk.com/v1/send', options)
            .then(response => response.json())
          .then(response => console.log(response))
          .catch(err => console.error(err));
          setIsLoading(false);
    }


    return(
        <>

            {isLoading ? 
            <>
                <div style = {{width:"100vw", height:"100vh", display:"flex", flexDirection:"column", justifyContent : "center", alignItems:"center", backgroundColor:"rgba(0, 0, 0, 0.67)", position:"fixed"}}>
                    <CircleLoader color = "#36d7b7"/>
                    <p style = {{textAlign:"center", color:"white"}}>Loading ...</p>
                </div>
            </>
             : <></>}

        <div id="hero-ref-doctor-container">
            <div id="hero-rec-banner">
                <p>STEM Elementary</p>
            </div>
            <div id="hero-ref-doctor-form">
                <input onChange={(e)=>{setEmail(e.target.value)}} type = "email" placeholder="Enter Email"/>
                <input onChange={(e)=>{setStudentName(e.target.value)}} type = "text" placeholder="Student Name"/>
                <input onChange={(e)=>{setDateRefferal(e.target.value)}} type = "date" placeholder="Date of Refferal"/>
                <input onChange={(e)=>{setCurrentSchool(e.target.value)}} type = "text" placeholder="Current School"/>
                <input onChange={(e)=>{setDob(e.target.value)}} type = "date" placeholder="Date Of Birth"/>
                <input onChange={(e)=>{setEthnicity(e.target.value)}} type = "text" placeholder="Ethnicity"/>
                <input onChange={(e)=>{setGender(e.target.value)}} type = "text" placeholder="Gender"/>
                <input onChange={(e)=>{setInsuranceType(e.target.value)}} type = "text" placeholder="Type of Insurance"/>
                <input onChange={(e)=>{setGuardianName(e.target.value)}} type = "text" placeholder="Legal Guardian Name"/>
                <input onChange={(e)=>{setAddress(e.target.value)}} type = "text" placeholder="Address"/>
                <input onChange={(e)=>{setCell(e.target.value)}} type = "text" placeholder="Phone"/>
                <input onChange={(e)=>{setGuardianName(e.target.value)}} type = "text" placeholder="Guardian Preffered Language"/>
                <input type = "text" placeholder="Student Preffered Language"/>
                <input type = "text" placeholder="Reffering Party/Title"/>
                <input onChange={(e)=>{setCell(e.target.value)}} type = "text" placeholder="Phone"/>
                <input type = "text" placeholder="Teacher/Grade"/>
                <input onChange={(e)=>{setIep(e.target.value)}} type = "text" placeholder="IEP"/>
                <input onChange={(e)=>{setBh(e.target.value)}} type = "text" placeholder="BH Services on IEP"/>
                <input onChange={(e)=>{setConsent(e.target.value)}} type = "date" placeholder="Date Obtained Consent"/>
            </div>
            <button onClick={()=>{send_email();}}>Submit</button>

            <div id="hero-recommendations-ref">
                <p>Recommendations</p>

                {
                            <>
                            <div id="hero-rec-card">
                                <img src = {cardImage} alt = "card"/>
                                <div id="hero-rec-card-subsection">
                                    <div className="subsection-rows">
                                        <h1 onClick={()=>{
                                            window.location.href = recommendations.url;
                                        }} >{recommendations.name}</h1>
                                    </div>
                                    <div className="subsection-rows">
                                        <p>{recommendations.name} is the best place for your improvement, based on the stats provided, our system came up with this recommendation.</p>
                                    </div>

                                </div>
                            </div>
                            </>
                }

            </div>
        </div>
        </>
    );
};

export default HeroRefDoctor;