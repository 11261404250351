import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {appPhyc} from "../../../firebase";

const storage = getStorage(appPhyc);

const uploadPDF = async (pdfBlob, fileName) => {
  try {
    const storageRef = ref(storage, `Reports/${fileName}.pdf`);

    const uploadTask = uploadBytes(storageRef, pdfBlob, { contentType: 'application/pdf' });

    await uploadTask;

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading PDF to Firebase Storage:', error);
    throw error;
  }
};

export default uploadPDF;
